body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.home_text__2LzXP {
  font-size: 12px;
  color: white;
  text-shadow: gray; }

.apply_input__3-hW9 {
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 26px;
  border-bottom: 1px solid gray; }

.apply_submit__2YFfj {
  margin-left: 1.5em;
  background: #6b96cf;
  border: none;
  color: white;
  font-size: 16px;
  width: 4em;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }
  .apply_submit__2YFfj:hover {
    background: #719eda; }
  .apply_submit__2YFfj:disabled {
    background: #bfbfbf; }

.apply_info__15t4Z {
  min-width: 300px;
  padding-top: 20px;
  height: 100px; }

.apply_applying__1jclx {
  font-size: 14px;
  color: gray; }

.apply_urlInputMaster__1DoFf, .apply_urlInputShare__2q4E2 {
  font-size: 12px;
  outline: none;
  width: 310px;
  color: gray;
  border: 1px solid darkgrey;
  padding: 2px 4px; }

.apply_copyShare__DLtQu, .apply_copyMaster__2D9Cz, .apply_link__1gvgz {
  font-size: 12px;
  background: #6b96cf;
  color: white;
  line-height: 20px;
  padding: 0 8px;
  display: inline-block;
  border: none;
  margin-left: .5em;
  float: right;
  cursor: pointer; }
  .apply_copyShare__DLtQu:hover, .apply_copyMaster__2D9Cz:hover, .apply_link__1gvgz:hover {
    opacity: 0.7; }

.apply_link__1gvgz {
  background: #9a71cf;
  text-decoration: none; }

.apply_title__o0DRQ {
  font-size: 12px;
  margin-top: 0.5em;
  margin-bottom: 0.2em; }

.apply_tips__5ZUjX {
  font-size: 14px;
  margin-bottom: 0.2em;
  color: darkolivegreen; }

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 30vmin;
  margin-bottom: 6vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

.App-port-input {
  font-size: inherit;
  width: 3em;
  background-color: transparent;
  color: #61dafb;
  text-align: center;
  border-bottom: 1px solid darkgray;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  padding: 0; }

.App-server-hint {
  font-size: 0.7em;
  color: gray; }

.App-link-status {
  outline: none;
  cursor: pointer;
  font-size: inherit;
  border: none;
  background: none; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

