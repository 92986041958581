$lightBlue: #61dafb;

.App {
  text-align: center; }
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 30vmin;
  margin-bottom: 6vmin; }
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }
.App-link {
  color: $lightBlue; }
.App-port-input {
  font-size: inherit;
  width: 3em;
  background-color: transparent;
  color: $lightBlue;
  text-align: center;
  border-bottom: 1px solid darkgray;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  padding: 0; }
.App-server-hint {
  font-size: 0.7em;
  color: gray; }
.App-link-status {
  outline: none;
  cursor: pointer;
  font-size: inherit;
  border: none;
  background: none; }
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
